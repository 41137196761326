import React from 'react'
import { graphql } from 'gatsby'
import LayoutAnleitungen from '../components/layout/LayoutAnleitungen'
import { Row, Col } from 'react-bootstrap'
import { DataGrid } from '@mui/x-data-grid'
import { navigate } from 'gatsby'
import SEO from '../components/seo'

const columns = [
  { field: 'title', headerName: 'Anleitung', width: 250 },
  { field: 'sequence', headerName: 'Sequenz', width: 150 },
  { field: 'subject', headerName: 'Thema', width: 150 },
  { field: 'effect', headerName: 'Wirkung', width: 150 },
  { field: 'working_modalities', headerName: 'Arbeitsmod', width: 150 },
  { field: 'bodypart', headerName: 'Körperteil', width: 150 },
  { field: 'tecnique', headerName: 'Technik', width: 450 },
  { field: 'material', headerName: 'Material', width: 150 },
  { field: 'source_book', headerName: 'Quelle', width: 150 },
]

function Anleitungen(props) {
  const rows = []
  console.log('Anleitungen data', props.data)
  const anleitungen = props.data.allPrismicAnleitung.nodes
  console.log('Anleitungen', anleitungen)

  // Sortieren der Anleitungen nach Titel
  anleitungen.sort((a, b) => {
    let fa = a.data.title.text.toLowerCase(),
      fb = b.data.title.text.toLowerCase()

    if (fa < fb) {
      return -1
    }
    if (fa > fb) {
      return 1
    }
    return 0
  })

  const handleEvent = (e) => {
    console.log('klicked', e)
    navigate(e.row.link)
  }

  // how to make element.tecnique a string with multiplw items
  for (let index = 0; index < anleitungen.length; index++) {
    const element = anleitungen[index].data.tecniques
    element.forEach((elem) => {
      element.tecnique = elem.tecnique
    })
  }

  return (
    <LayoutAnleitungen>
      <Row className="p-3">
        <Col xs={12}>
          <h2 className="mt-5">Anleitungen</h2>
          <DataGrid
            autoHeight
            onRowClick={handleEvent}
            columns={columns}
            rows={rows}
          />
          {/* Make thies responsive for mobile */}
          {/* <StyledUl> */}
          {anleitungen.map((anleitung, i) => {
            rows.push({
              id: i,
              title: anleitung.data.title.text,
              link: anleitung.url,
              sequence: anleitung.data.sequence,
              subject: anleitung.data.subject,
              effect: anleitung.data.effect,
              working_modalities: anleitung.data.working_modalities,
              bodypart: anleitung.data.bodypart,
              tecnique: anleitung.data.tecniques.tecnique,
              material: anleitung.data.material,
              source_book: anleitung.data.source_book,
            })
            /*               return (
                <li className="fs-3" key={anleitung.url}>
                  <Link to={anleitung.url}>{anleitung.data.title.text}</Link>
                </li>
              ) */
          })}
          {/* </StyledUl> */}
        </Col>
      </Row>
    </LayoutAnleitungen>
  )
}

export default Anleitungen

export const query = graphql`
  query MyQuery {
    allPrismicStunde {
      edges {
        node {
          data {
            title {
              text
            }
          }
          url
        }
      }
    }
    allPrismicAnleitung {
      nodes {
        data {
          title {
            text
          }
          sequence
          material
          source_book
          subject
          effect
          working_modalities
          bodypart
          tecniques {
            tecnique
          }
        }
        url
      }
    }
  }
`
